import React, { useState } from "react";
import options from "./data";
import { Button } from "../ButtonElement";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  ServicesH3,
  Subtitle,
} from "./ProjectsElements";

const ProjectSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const openInNewTab = (url) => {
    window.open(url, "noreferrer");
  };

  return (
    <>
      <ServicesContainer id="proyectos">
        <ServicesH1>Proyectos</ServicesH1>
        <Subtitle>
          Gracias a nuestras actividades se ha logrados comprometer más de 2000
          hectareas para la implementación de proyectos de Arborización,
          Reciclaje y Compostaje en 7 comunidades Agrarias, tambien se han
          registrado más de 300 toneladas de residuos solidos revalorizados.{" "}
        </Subtitle>
        <ServicesWrapper >
          {options.map((option) => (
            <ServicesCard onClick={() => openInNewTab(option.sender)} style={{bordercolor: option.certificado}}>
              
              <ServicesH2>{option.proceso}</ServicesH2>
              <ServicesIcon src={option.symbol} />
              <ServicesH3>({option.owner})</ServicesH3>
              <ServicesP>{option.desc}</ServicesP>
             
              {/* <ServicesP>{option.sender}</ServicesP> */}
            </ServicesCard>
          ))}
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default ProjectSection;
