import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages";
import SigninPage from "./pages/signin";
import ProjecPage from "./pages/projects";
import Nft from "./components/Projects/nft";
import NotFound from "./pages/NotFound";
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/signin" component={SigninPage} exact />
        <Route path="/projects/" component={ProjecPage} exact />
        <Route path="/nft/:id" component={Nft} exact />
         <Route path='*' element={<NotFound />}/>
      </Switch>
    </Router>
  );
}

export default App;
