import Icon1 from "../../images/svg-8.svg";
import Icon2 from "../../images/svg-9.svg";
export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topline: "Premium Bank",
  headline: "Nosotros",
  description:
    "R3colecta, una organización social comprometida con el cambio positivo. Nuestro enfoque está centrado en respaldar y fortalecer emprendimientos que buscan marcar la diferencia en tres frentes: económico, social y ambiental. ¿Cómo lo logramos? A través de alianzas estratégicas que impulsan la financiación y la ejecución de proyectos enfocados en la forestación, el reciclaje y los servicios ecosistémicos.",
  buttonLabel: "Contactar",
  imgStart: false,
  img: Icon2,
  alt: "Car",
  dark: true,
  primary: false,
  darkText: false,
  hidden : false,
  enlace:"https://aswtwech.typeform.com/to/QZBUvuta"
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topline: "Unlimited Access",
  headline: "Proyectos",
  description:
    "Gracias a nuestras actividades se ha logrados comprometer más de 2000 hectareas para la implementación de proyectos de Arborización, Reciclaje y Compostaje en 8 comunidades, tambien se han registrado más de 400 toneladas de residuos solidos revalorizados. ",
  buttonLabel: "ver proyectos",
  imgStart: true,
  img: Icon1,
  alt: "puggybank",
  dark: true,
  primary: false,
  darkText: true,
 
  enlace:"#proyectos"
};
export const homeObjThree = {
  id: "signup",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topline: "Join Our Team",
  headline: "Evalúa tu Proyecto",
  description:
    "¿Eres lider comunitario, representante de tu barrio o encargado de sostenibilidad en una institución ?, ¿ Buscas implementar un proyecto de impacto Socio/ambiental?, inicia tu evaluación con nosotros!",
  buttonLabel: "Comenzar",
  imgStart: true,
  img: "/images/svg-3.svg",
  alt: "papers",
  dark: true,
  primary: false,
  darkText: true,
  enlace:"https://aswtwech.typeform.com/to/ly0S4Q"
};
