import React, { useState } from "react";
import { Button } from "../ButtonElement";
import Video from "../../videos/video2.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <HeroContainer id="home">
        <HeroBg>
          <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
        </HeroBg>
        <HeroContent>
          <HeroH1>R3COLECTA</HeroH1>
          <HeroP>
           Impulsando proyectos de triple impacto
          </HeroP>
          <HeroBtnWrapper>
            {/* <Button
              to="signup"
              onMouseOver={onHover}
              primary={true}
              dark={true}
              big={false}
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
            >
              Saber Más {hover ? <ArrowForward /> : <ArrowRight />}
            </Button> */}
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
