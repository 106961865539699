import React from "react";
import Icon1 from "../../images/svg-8.svg";
import Icon2 from "../../images/svg-10.svg";
import Icon3 from "../../images/svg-7.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Actividades</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Proyectos</ServicesH2>
          <ServicesP>
            Implementación de Proyectos de Triple Impacto, en Barrios, Comunidades e Instituciónes. 
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Acompañamiento</ServicesH2>
          <ServicesP>
          Acompañamos los proyectos para un correcto registro de información, verificación, validación y reporte bajo estandares ASG.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Canalización de Fondos</ServicesH2>
          <ServicesP>
            Conectamos a las personas, empresas, instituciones y gobiernos para el financiamiento de Proyectos.
          </ServicesP>
        </ServicesCard>
        
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
