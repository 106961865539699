import Icon1 from "../../images/pinaya.jpg";
import Icon2 from "../../images/choquecota.jpg";
import Icon3 from "../../images/mutuhuaya.jpg";
import Icon4 from "../../images/coqueni.jpg";
import Icon5 from "../../images/ibet2.jpeg";
import Icon6 from "../../images/forestacion_verde.jpg";
import Icon7 from "../../images/fondo.jpg";

var options = [
  {
    id: 1,
    proceso: "Reforestación Sostenible PINAYA",
    desc: "Reforestar con 45,000 árboles",
    owner: "Comunidad Pinaya - La Paz - Bolivia",
    sender: "/nft/1",
    ammount: "false",
    certificado:"true",
    estado:"en proceso",
    symbol: Icon1,
    data_project : {
      data:"420.986",
      products: "15",
      detalle: "El objetivo de este proyecto de reforestación es lograr un mejoramiento ambiental sostenible y la recuperación de la flora y fauna de la Comunidad de Pinaya, a través de una estrategia equilibrada que involucra tanto la plantación de árboles nativos, exóticos y productivos.  Se busca promover el bienestar económico de la comunidad local al dedicar el 40% de los árboles plantados a especies productivas. El éxito de cada fase estará vinculado a la supervivencia del 80% de los árboles plantados y al compromiso activo de la comunidad en el cuidado y mantenimiento de los recursos forestales.  Se fomentará la participación comunitaria a través de capacitación, educación ambiental y la creación de oportunidades económicas derivadas de los árboles frutales y otros productos forestales. La sostenibilidad es esencial para el éxito a largo plazo de la reforestación. Al promover árboles productivos, se crea un incentivo para que la comunidad cuide y mantenga los árboles plantados, lo que garantiza la supervivencia a largo plazo de la reforestación.",
      enlace:""
    }
  },
  {
    id: 2,
    proceso: "Reforestación Sostenible CHOQUECOTA",
    desc: "Reforestar con 45,000 árboles",
    owner: "Comunidad Choquecota - La Paz - Bolivia",
    sender: "/nft/2",
    ammount: "false",
    certificado:"true",
    estado:"en proceso",
    symbol: Icon2,
    data_project : {
      data:"420.986",
      products: "15",
      detalle: "El objetivo de este proyecto de reforestación es lograr un mejoramiento ambiental sostenible y la recuperación de la flora y fauna de la Comunidad de Choquecota, a través de una estrategia equilibrada que involucra tanto la plantación de árboles nativos, exóticos y productivos, se busca promover el bienestar económico de la comunidad local al dedicar el 40% de los árboles plantados a especies productivas, el éxito de cada fase estará vinculado a la supervivencia del 80% de los árboles plantados y al compromiso activo de la comunidad en el cuidado y mantenimiento de los recursos forestales, se fomentará la participación comunitaria a través de capacitación, educación ambiental y la creación de oportunidades económicas derivadas de los árboles frutales y otros productos forestales, la sostenibilidad es esencial para el éxito a largo plazo de la reforestación. Al promover árboles productivos, se crea un incentivo para que la comunidad cuide y mantenga los árboles plantados, lo que garantiza la supervivencia a largo plazo de la reforestación",
      enlace:""
    }
  },
    {
    id: 3,
    proceso: "Reforestación Sostenible MUTUHUAYA",
    desc: "Reforestar con 45,000 árboles",
    owner: "Comunidad Mutuhuaya - La Paz - Bolivia",
    sender: "/nft/3",
    ammount: "false",
    certificado:"true",
    estado:"en proceso",
    symbol: Icon3,
    data_project : {
      data:"420.986",
      products: "15",
      detalle: "El objetivo de este proyecto de reforestación es lograr un mejoramiento ambiental sostenible y la recuperación de la flora y fauna de la Comunidad de Mutuhuaya, a través de una estrategia equilibrada que involucra tanto la plantación de árboles nativos, exóticos y productivos, se busca promover el bienestar económico de la comunidad local al dedicar el 40% de los árboles plantados a especies productivas, el éxito de cada fase estará vinculado a la supervivencia del 80% de los árboles plantados y al compromiso activo de la comunidad en el cuidado y mantenimiento de los recursos forestales, se fomentará la participación comunitaria a través de capacitación, educación ambiental y la creación de oportunidades económicas derivadas de los árboles frutales y otros productos forestales, la sostenibilidad es esencial para el éxito a largo plazo de la reforestación. Al promover árboles productivos, se crea un incentivo para que la comunidad cuide y mantenga los árboles plantados, lo que garantiza la supervivencia a largo plazo de la reforestación",
      enlace:""
    }
    
  },
   {
    id: 4,
    proceso: "Reforestación Sostenible COQUENI",
    desc: "Reforestar con 1500 árboles",
    owner: "Barrio Coqueni – Calle 46 chasquipampa - La Paz - Bolivia",
    sender: "/nft/4",
    ammount: "false",
    certificado:"true",
    estado:"en proceso",
    symbol: Icon4,
    data_project : {
      data:"420.986",
      products: "15",
      detalle: "El objetivo de este proyecto de reforestación es lograr un mejoramiento ambiental sostenible y la recuperación de la flora y fauna de la Coqueni, a través de una estrategia equilibrada que involucra tanto la plantación de árboles nativos, exóticos y productivos, se busca promover el bienestar económico de la comunidad local al dedicar el 40% de los árboles plantados a especies productivas, el éxito de cada fase estará vinculado a la supervivencia del 80% de los árboles plantados y al compromiso activo de la comunidad en el cuidado y mantenimiento de los recursos forestales, se fomentará la participación comunitaria a través de capacitación, educación ambiental y la creación de oportunidades económicas derivadas de los árboles frutales y otros productos forestales, la sostenibilidad es esencial para el éxito a largo plazo de la reforestación. Al promover árboles productivos, se crea un incentivo para que la comunidad cuide y mantenga los árboles plantados, lo que garantiza la supervivencia a largo plazo de la reforestación",
      enlace:""
    }
  },
  {
    id: 5,
    proceso: "ACOPIO 2.0",
    desc: "Implementación de la APPLICACIÓN MOVIL R3COLECTA para la gestión de Residuos Solidos",
    owner: "Calle 35, Chasquipampa - La Paz - Bolivia",
    sender: "/nft/5",
    ammount: "true",
    certificado:"true",
    estado:"finalizado",
    symbol: Icon5,
    data_project : {
      data:"420.986",
      products: "15",
      detalle: "En total, se há reciclado más de 420 toneladas de materiales, reduciendo las emisiones de gases de efecto invernadero que son emitidos al medio ambiente.",
      enlace:""
    }
  },
  {
    id: 6,
    proceso: "Reforestación Sostenible FORESTACION VERDE",
    desc: "Arborización con 3.000.000 árboles",
    owner: "Municipio de Palca – 74 Comunidades - La Paz - Bolivia",
    sender: "/nft/6",
    ammount: "true",
    certificado:"red",
    estado:"en proceso",
    symbol: Icon6,
    data_project : {
      data:"420.986",
      products: "15",
      detalle: "El objetivo de este proyecto de reforestación es lograr un mejoramiento ambiental sostenible y la recuperación de la flora y fauna de la MUNICIPIO DE PALCA, a través de una estrategia equilibrada que involucra tanto la plantación de árboles nativos, exóticos y productivos, se busca promover el bienestar económico de la comunidad local al dedicar el 40% de los árboles plantados a especies productivas, el éxito de cada fase estará vinculado a la supervivencia del 80% de los árboles plantados y al compromiso activo de la comunidad en el cuidado y mantenimiento de los recursos forestales, se fomentará la participación comunitaria a través de capacitación, educación ambiental y la creación de oportunidades económicas derivadas de los árboles frutales y otros productos forestales, la sostenibilidad es esencial para el éxito a largo plazo de la reforestación. Al promover árboles productivos, se crea un incentivo para que la comunidad cuide y mantenga los árboles plantados, lo que garantiza la supervivencia a largo plazo de la reforestación.",
      enlace: "https://certifika.io/certification/certificado-de-arborizacion-de-plantas-nativas/",
      pdf:"https://bafybeieck7aecmuq3y27sqzdehe5hnm4uxpujormsvehtuagvz4zmuo2wu.ipfs.w3s.link"
    }
  },
  {
    id: 7,
    proceso: "Reforestación Sostenible UNIDAD EDUCATIVA PEDREGAL",
    desc: "Arborización con 45 árboles",
    owner: "Municipio de La Paz – Zona Pedregal - La Paz - Bolivia",
    sender: "/nft/7",
    ammount: "true",
    certificado:"red",
    estado:"en proceso",
    symbol: Icon7,
    data_project : {
      data:"420.986",
      products: "15",
      detalle: "El objetivo de este proyecto de reforestación es lograr un mejoramiento ambiental sostenible y la recuperación de la flora y fauna de la MUNICIPIO DE PALCA, a través de una estrategia equilibrada que involucra tanto la plantación de árboles nativos, exóticos y productivos, se busca promover el bienestar económico de la comunidad local al dedicar el 40% de los árboles plantados a especies productivas, el éxito de cada fase estará vinculado a la supervivencia del 80% de los árboles plantados y al compromiso activo de la comunidad en el cuidado y mantenimiento de los recursos forestales, se fomentará la participación comunitaria a través de capacitación, educación ambiental y la creación de oportunidades económicas derivadas de los árboles frutales y otros productos forestales, la sostenibilidad es esencial para el éxito a largo plazo de la reforestación. Al promover árboles productivos, se crea un incentivo para que la comunidad cuide y mantenga los árboles plantados, lo que garantiza la supervivencia a largo plazo de la reforestación.",
      enlace: "https://certifika.io/certification/certificado-de-arborizacion-unidad-educativa-pedregal/",
      pdf:"https://bafkreiamsru664cthjzxqp6u4dpieilkhuxdr3mjkben6ihv5yr5cip4m4.ipfs.w3s.link"
    }
  },
];

export default options;
