import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import NavbarProject from "../components/Navbar";
import Footer from "../components/Footer";
const ProjecPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavbarProject toggle={toggle} />
      {/* <Nft {...homeObjTwo} /> */}
      
       {/* <Project /> */}
      <Footer />
       
    </>
  );
};

export default ProjecPage;
