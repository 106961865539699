import React, { useState, useEffect } from "react";
import options from "./data";
import { Card, Row, Col, Button } from 'react-bootstrap';
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import ProjectSection from "../Projects";
import InfoSection from "../InfoSection";
import {
  homeObjOne,
  homeObjThree,
  homeObjTwo,
} from "../InfoSection/data";
import Services from "../Services";
import Footer from "../Footer";
function Nft({ cta }) {
    const last = window.location.href.split('/').pop();
    const index = last - 1;
    const [account, setAccount] = useState(index);

    useEffect(() => {

        //setAccount(last);
        console.log(last);
        //setData();
        console.log(options[account]);
    });

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />


            <Row style={{ padding: '30px', marginTop: '30px' }}>
                <Col xs={12} md={12} s={{ order: 1 }}>



                    <Card key={options[account].id} className="text-center" border="primary" style={{ height: '100%', width: '100%' }} >
                        {/* <Card.Header><h3>PROYECTO: {options[account].proceso}</h3></Card.Header> */}
                        <Card.Body style={{ height: '100%', padding: '1%', textalign: 'center', borderRadius: '50%' }}>
                            <Row>

                                <Col xs={12} lg={6} style={{ padding: '5%', textalign: 'left' }} >

                                    <Card.Title><h1>{options[account].proceso}</h1></Card.Title>
                                    <br></br>
                                    <Col md={6}>
                                        <Card.Text>
                                            {options[account].desc}
                                        </Card.Text>

                                        <br></br>
                                    </Col>
                                    <Col lg={6}>
                                        <Card.Img variant="center" src={options[account].symbol} style={{
                                            width: "70%",
                                            height: "70%",
                                            borderRadius: 100 / 5,
                                            overflow: "hidden",
                                            borderWidth: 1,

                                        }} />
                                    </Col>
                                </Col>

                            </Row>
                            <Row style={{ padding: '20px' }}>
                                <Col md={3} >
                                    <Card.Subtitle className="mb-2 text-muted"> <strong> Objetivo :</strong>   </Card.Subtitle>
                                    <Card.Text>
                                        {options[account].desc}
                                    </Card.Text>
                                </Col>
                                <br/>
                                <Col md={3}>
                                    <Card.Subtitle className="mb-2 text-muted">  <strong>Ubicación :</strong>  </Card.Subtitle>
                                    <Card.Text>
                                        {options[account].owner}
                                    </Card.Text>
                                </Col>
                                <br/>
                                <Col md={3}>
                                    <Card.Subtitle className="mb-2 text-muted"> <strong>Descripción: </strong>   </Card.Subtitle>
                                    <Card.Text>
                                        {options[account].data_project.detalle}
                                    </Card.Text>
                                </Col>
                                <br/>
                                <Col md={3}>
                                    <Card.Subtitle className="mb-2 text-muted"> <strong> Comprobante de Certificación: </strong>  </Card.Subtitle>
                                </Col>
                                <Col md={12}>
                                    <Card.Text>
                                       <a href= {options[account].data_project.enlace}> Verificar </a>
                                    </Card.Text>
                                </Col>
                                <br/>
                                <Col md={3}>
                                    <Card.Subtitle className="mb-2 text-muted"> <strong> Estado: </strong>  </Card.Subtitle>
                                </Col>
                                <Col md={12}>
                                    <Card.Text>
                                    {options[account].estado}
                                    </Card.Text>
                                </Col>
                                <br/>
                                <Col md={3}>
                                    <Card.Subtitle className="mb-2 text-muted"> <strong> Certificados: </strong>  </Card.Subtitle>
                                </Col>
                                <Col md={12}>
                                    <Card.Text>
                                   
                                    <a href=  {options[account].data_project.pdf}> Descargar Certificado PDF </a>
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                        {/* <Card.Footer className="text-muted">{options[account].id}</Card.Footer> */}
                    </Card>

                </Col>
            </Row>
            
            <ProjectSection />
            {/* <InfoSection {...homeObjTwo} /> */}
            <Services />
            <InfoSection {...homeObjThree} />
            <InfoSection {...homeObjOne} />
            <Footer />
        </div>
    )
}

export default Nft;